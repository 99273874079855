// AuthStore.ts

import { observable, flow, action } from 'mobx';
import {
  getAuthUrl,
  getValidatedUserInfo,
  createUser,
  login as loginApi,
  updatePassword,
  setWizardCompleted,
  getPasswordResetInfo,
} from '../services/api';
import { ToastStore } from '@stores';
import { PATHS } from '../constants/nav-items';
import { navigate } from '@reach/router';
import CurrentUserStore from './CurrentUserStore';
import AgreementStore from './AgreementStore';
import MessageStore from './MessageStore';
import NewsStore from './NewsStore';
import PaymentStore from './PaymentStore';
import FaultReportStore from './FaultReportStore';

export const TOKEN = 'auth_token';

interface LoginObj {
  email: string;
  password: string;
}

export class AuthModel {
  @observable isWizardCompleted = true;
  @observable userCreated = false;
  @observable isAuthenticated? = undefined as boolean | null | undefined;

  @observable authUrl = "";
  @observable authUrlPwd = "";

  @observable state: StoreState = "Idle";

  @observable validatedUser: any = null; // Stores user data after validation
  @observable activeAccount: boolean = false; // Indicates if user has an active account
  @observable stateToken: string = ""; // Stores state token if needed
  @observable passwordResetToken: string = ""; // Stores password reset token

  // Gets the authentication URL for account creation
  getAuthUrl = flow(function* (this: AuthModel, callbackUri: string) {
    try {
      const response = yield getAuthUrl(callbackUri);
      this.authUrl = response.data;
      return this.authUrl;
    } catch (error) {
      console.error('Error getting auth URL:', error);
      throw error;
    }
  }).bind(this);

  // Gets the authentication URL for password reset
  getAuthUrlPwd = flow(function* (this: AuthModel, callbackUri: string) {
    try {
      const response = yield getAuthUrl(callbackUri);
      this.authUrlPwd = response.data;
      return this.authUrlPwd;
    } catch (error) {
      console.error('Error getting auth URL for password reset:', error);
      throw error;
    }
  }).bind(this);

  @action
  resetStore() {
    this.userCreated = false;
    this.isAuthenticated = false;
    this.authUrl = "";
    this.authUrlPwd = "";
    this.state = "Idle";
    this.validatedUser = null;
    this.activeAccount = false;
    this.stateToken = "";
    this.passwordResetToken = "";
  }

  // Retrieves user info after strong authentication
  getValidatedUserInfo = flow(function* (
    this: AuthModel,
    params: { code: string; state: string }
  ) {
    this.state = "Loading";
    try {
      const response = yield getValidatedUserInfo(params);
  
      if (response.data) {
        const { activeAccount, email, identityToken } = response.data;
        this.activeAccount = activeAccount;
        this.validatedUser = {
          email: email,
          identityToken: identityToken,
        };
        return response.data;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (err) {
      console.error('Error in getValidatedUserInfo:', err);
      navigate(PATHS.auth.accountSetup);
      this.state = "Error";
      throw err;
    }
  }).bind(this);

  // Retrieves password reset info after strong authentication
  getPasswordResetInfo = flow(function* (
    this: AuthModel,
    params: { code: string; state: string }
  ) {
    this.state = "Loading";
    try {
      const response = yield getPasswordResetInfo(params);
  
      if (response.data && response.data.passwordResetToken) {
        this.passwordResetToken = response.data.passwordResetToken;
        this.validatedUser = { email: response.data.email };
        return response.data;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (err) {
      console.error('Error in getPasswordResetInfo:', err);
      navigate(PATHS.changePassword);
      this.state = "Error";
      throw err;
    }
  }).bind(this);

  // Creates a new user account
  createUser = flow(function* (
    this: AuthModel,
    data: any,
    submitCallback: (err?: string) => void
  ) {
    this.state = "Loading";
    try {
      yield createUser(data);
      this.userCreated = true;
      this.state = "Success";
      submitCallback();
    } catch (err: any) {
      let msg = "pages.register.registrationFailed";
      if (err.response && err.response.status === 409) {
        msg = "pages.register.registrationFailedEmailExists";
      }
      this.state = "Error";
      this.userCreated = false;
      submitCallback(err);
      ToastStore.showError(msg, true);
      throw err;
    }
  }).bind(this);

  // Marks the wizard as completed
  setWizardCompleted = flow(function* (this: AuthModel, email: string) {
    try {
      const res = yield setWizardCompleted(email);
      this.isWizardCompleted = true;
      return res;
    } catch (err) {
      this.isWizardCompleted = false;
      throw err;
    }
  }).bind(this);

  // Handles user login
  login = flow(function* (this: AuthModel, formData: LoginObj, cb: Function) {
    try {
      const response = yield loginApi(formData);
      if (response === undefined) return;
      if (response.data.user) {
        this.isWizardCompleted = response.data.user.wizardCompleted;
      }
      // Store token in localStorage
      if (typeof window !== 'undefined' && window.localStorage && response) {
        // Token expires in 30 days
        localStorage.setItem(
          "token_expires",
          JSON.stringify(
            new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).getTime()
          )
        );
        localStorage.setItem(TOKEN, response.data.access_token);
        this.refreshIsAuthenticated();
      }
      return response;
    } catch (error) {
      cb(error);
      throw error;
    }
  }).bind(this);

  // Logs out the user
  logout = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem("token_expires");
      this.refreshIsAuthenticated();
    }
    AgreementStore.reset();
    CurrentUserStore.resetStore();
    MessageStore.resetStore();
    NewsStore.resetStore();
    this.resetStore();
    PaymentStore.reset();
    FaultReportStore.resetStore();
  };

  @action refreshIsAuthenticated = () => {
    if (typeof window !== "undefined") {
      this.isAuthenticated = !!localStorage.getItem(TOKEN);
      return;
    }
    this.isAuthenticated = false;
  };

  // Updates the user's password
  updatePassword = flow(function* (
    this: AuthModel,
    passwordResetToken: string,
    password: string,
    cb: () => void
  ) {
    try {

      console.log('updatePassword()')
      console.log(passwordResetToken)
      console.log('passwordResetToken')
      console.log('password')
      console.log(password)
      yield updatePassword({
        passwordResetToken,
        password,
      });
      ToastStore.showSuccess("Password updated successfully.", true);
      cb();
      this.state = "Success";
    } catch (error) {
      console.error('Error in updatePassword:', error);
      ToastStore.showError("Failed to update password.", true);
      this.state = "Error";
      cb();
      throw error;
    }
  }).bind(this);
}

const AuthStore = new AuthModel();

export default AuthStore;
