// gatsby-browser.js

import "typeface-roboto";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import wrapWithProvider from "./wrap-with-provider.tsx";

export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = ({ location }) => {
  scrollToAnchor(location);
};

const scrollToAnchor = (location, mainNavHeight = 0) => {
  // Check for location to prevent build failures
  setTimeout(() => {
    if (location && location.hash) {
      const item = document.querySelector(`${location.hash}`);
      if (item) {
        const itemTop = item.offsetTop;
        window.scrollTo({
          top: itemTop - mainNavHeight,
          behavior: "smooth",
        });
      }
    }
  }, 500);
  return true;
};
